import {useState} from 'react';
import Form from 'react-bootstrap/Form';
import { Button,Container, } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { FallingLines } from 'react-loader-spinner'

import axios from "axios";



function sendPaiemnForGen(abn,idtran_operatore,idwortispay,tel,errorr,success){
  let data = JSON.stringify({
    "abn": abn,
    "idtran_operatore": idtran_operatore,
    "idwortispay": idwortispay,
    "tel": tel
  });
  
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: 'https://elig.congotelecom.cg/apiv1/api/paie/speed',
    headers: { 
      'Content-Type': 'application/json'
    },
    data : data
  };
  
  axios.request(config)
  .then((response) => {
    console.log(JSON.stringify(response.data));
    success(response.data)
  })
  .catch((error) => {
    console.log(error);
    errorr(error)
  });
}


function  App()  {
	
  // const  [abn, setAbn] =  useState('');
  // const  [idtrans_operator, setIdtrans_operator] =  useState('');
  // const  [tel, setTel] =  useState('');
  const  [isLoad, setIsLoad] =  useState(false);
  const  [datas, setData] =  useState({});

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


	const [formData, setFormData] = useState({abn: "",idtrans_operator: "",tel: "",idspeedpay:""});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoad(true)
    sendPaiemnForGen(formData.abn,formData.idtrans_operator,formData.idspeedpay,formData.tel,(err) => {
      console.log(err)
    },(respp) => {
      setData(respp)
      setIsLoad(false)
      handleShow()
    })
};

  if(isLoad){
    return (<div className="d-flex align-items-center  
    justify-content-center vh-100"><Container>
      <FallingLines
      color="#4fa94d"
      width="100"
      visible={true}
      ariaLabel="falling-circles-loading"
      />
    </Container></div>)
  }

  return (
    <div className="d-flex align-items-center  
    justify-content-center vh-100">
    <Container>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>Numero d'abonnement</Form.Label>
          <Form.Control type="text" id="abn" name="abn" value={formData.abn} onChange={handleChange} />
        </Form.Group>

        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>Numero ayant payer</Form.Label>
          <Form.Control type="text" id="tel" name="tel" value={formData.tel} onChange={handleChange} />
        </Form.Group>

        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>WortispayId</Form.Label>
          <Form.Control id="idspeedpay" name="idspeedpay" value={formData.idspeedpay} onChange={handleChange} />
        </Form.Group>

        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>Id Transaction Operateur ()</Form.Label>
          <Form.Control type="text" id="idtrans_operator" name="idtrans_operator" value={formData.idtrans_operator} onChange={handleChange} />
        </Form.Group>

        <Button type="submit">Submit</Button>
      </Form>
    </Container>
    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body> 
          <code>
          {JSON.stringify(datas, null, 2)}  
          </code>  
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
    
  );
};

export default App;
